<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_destination')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form>
                    <v-autocomplete v-model="editedData.from"
                                    :label="$t('from')"
                                    required
                                    dense
                                    outlined
                                    :items="selectData.destinations"
                                    item-text="value"
                                    item-value="id"
                                    :error-messages="fromErrors"
                                    @input="$v.editedData.from.$touch()"
                                    @blur="$v.editedData.from.$touch()"
                                    @change="changeData"
                    ></v-autocomplete>

                    <v-autocomplete v-model="editedData.to"
                                    :label="$t('to')"
                                    required
                                    dense
                                    outlined
                                    :items="destinations"
                                    item-text="value"
                                    item-value="id"
                                    :error-messages="toErrors"
                                    @input="$v.editedData.to.$touch()"
                                    @blur="$v.editedData.to.$touch()"
                    ></v-autocomplete>

                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editDivisionForm",
        props: ["success"],
        computed: {
            fromErrors() {
                const errors = [];
                if (!this.$v.editedData.from.$dirty) return errors;
                !this.$v.editedData.from.required &&
                errors.push("From is required");
                return errors;
            },
            toErrors() {
                const errors = [];
                if (!this.$v.editedData.to.$dirty) return errors;
                !this.$v.editedData.to.required &&
                errors.push("To is required.");
                return errors;
            }
        },
        data() {
            return {
                selectData: {
                    destinations: [],
                    selectedValue: null
                },
                destinations: [],
                province: [],

                editedData: {
                    from: "",
                    to: "",
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };

        },
        validations: {
            editedData: {
                from: {required},
                to: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                const destinations = await axios.get('StaticValues/DDLCommonStaticValuesListAsync/100')
                this.selectData.destinations = destinations.data
            },
            changeData(val) {
                this.destinations = this.selectData.destinations.filter(destination => destination.value !== val);
                console.log(this.selectData.destinations)
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        from: this.editedData.from,
                        to: this.editedData.to
                    };
                    axios.post("Transportation/InsertIntoTransportationDestinationAsync", param).then(response => {
                        console.log({response})
                        if (response.status == 200) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Destination added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.from = "";
                this.editedData.to = "";
            }
        }
    };
</script>

<style scoped>
</style>